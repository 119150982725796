@import "../node_modules/ngx-bar-rating/themes/br-default-theme";
$color: #8912fc;
$text-color: #ffffff;
$selected: #fea569;
.btn-bg {
  background: $color;
  color: $text-color;
}
.btn-color {
  color: $color;
}
.btn-bg:hover {
  color: $text-color;
}

a {
  text-decoration: none;
}
.ngx-pagination .current {
  padding: 0.1875rem 0.625rem;
  background: $color !important;
  color: $text-color;
}
.star-rating {
  // color: #fea569;
  display: flex;
}
.br-default .br-selected:after {
  color: $selected;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}

.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  outline: none;
  box-shadow: none;
}

.form-control:focus,
.form-select:focus {
  border-color: $color;
  outline: none;
  box-shadow: none;
}
.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: $text-color;
  background-color: $color;
  border-color: $color;
}
.btn-outline-secondary:hover {
  color: $text-color;
  background-color: $color;
  border-color: $color;
}
.btn-outline-secondary {
  color: $color;
  border-color: $color;
}
.br {
  margin: -5px 0 !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
